import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Update June 7, 2018 - Hall of Shame`}</h3>
    <p>{`This article is a Hall of Shamer`}{`™`}{` for irrelevance. Webpack & NPM Scripts
have made Grunt useless to me.`}</p>
    <p>{`In this article I'm going to show you how to leverage Grunt.js to completely
refactor your workflow. Follow in the footsteps of some of the most
`}<a parentName="p" {...{
        "href": "https://github.com/jquery/jquery/commits/master/grunt.js"
      }}>{`prolific open source projects`}</a>{`
in the world and leave the grunt work to Grunt.js.`}</p>
    <h2>{`What is Grunt.js?`}</h2>
    <p>{`Grunt.js is a fantastic task-based command line tool written in JavaScript on
top of the wonderful Node.js platform. You can leverage Grunt.js to script away
all of your grunt work. Tools and procedures that you historically ran and
configured yourself, you can now abstract behind a convention based command line
interface with a consistent means of configuration. You can write your most
complicated tasks `}<em parentName="p">{`once`}</em>{` and leverage them in all of your projects using project
specific configuration.`}</p>
    <p>{`What are these tasks I keep referencing? Well, really thats up to you, but a few
of the most common ones are
`}<a parentName="p" {...{
        "href": "https://github.com/gruntjs/grunt-contrib-concat"
      }}>{`concatenating files`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://github.com/gruntjs/grunt-contrib-jshint"
      }}>{`linting`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://github.com/karma-runner/grunt-karma"
      }}>{`testing`}</a>{` your code, and
`}<a parentName="p" {...{
        "href": "https://github.com/gruntjs/grunt-contrib-uglify"
      }}>{`minification`}</a>{`. Grunt.js
doesn't limit you to JavaScript specific tasks either, because Grunt.js is built
on top of Node.js you can leverage all the power of Node in your tasks. Even if
your tool isn't implemented in JavaScript we can defer tasks to child processes,
using their command line interface or even a web service. For example, the
`}<a parentName="p" {...{
        "href": "https://github.com/gruntjs/grunt-contrib-compass"
      }}>{`grunt-contrib-compass`}</a>{` task,
which allows you to compile your SCSS/SASS stylesheets using the excellent
`}<a parentName="p" {...{
        "href": "http://compass-style.org/"
      }}>{`Compass framework`}</a>{` (a ruby program), is implemented
using the Compass provided command line interface. The point is that just about
any tool can be abstracted behind a Grunt task.`}</p>
    <h2>{`Why should I use Grunt.js?`}</h2>
    <h3>{`Consistency`}</h3>
    <p>{`Grunt.js provides a consistent interface for configuring and using any task.`}</p>
    <h3>{`Utility`}</h3>
    <p>{`Grunt.js allows you to run your tasks when monitored files change or manually
using the command line. It also allows you to aggregate tasks using aliases,
this is a very powerful feature which allows you to abstract the details of a
more generic task. For example, one might abstract linting, testing,
concatenating, and minification behind a task named "build".`}</p>
    <h3>{`Community`}</h3>
    <p>{`Grunt.js has a strong and rapidly growing community. The barrier to entry for
using and customizing Grunt is so minimal that there is already a whole bunch
great tasks available for use today. Take a look at the bountiful
`}<a parentName="p" {...{
        "href": "http://gruntjs.com/plugins"
      }}>{`plugin landscape`}</a>{`.`}</p>
    <h3>{`Power & Flexibility`}</h3>
    <p>{`Grunt.js provides all the power of Node.js for your tasks. It also provides some
very fundamental and powerful abstractions for common needs, like working with
the file system or logging to the standard out (stdout).`}</p>
    <h2>{`Who is using it?`}</h2>
    <p>{`Lots of excellent projects are leveraging Grunt.js. To name a few:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/angular/angular.js/commits/master/Gruntfile.js"
        }}>{`angular.js`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jquery/jquery/commits/master/grunt.js"
        }}>{`jQuery`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/search?q=grunt.js"
        }}>{`Many, many, many more…`}</a></li>
    </ul>
    <h2>{`A Grunt.js Workflow`}</h2>
    <p>{`The project structure we are going to use in this example is straight forward.
We have a folder called "src", which has our application's source code and a
folder called "test" that has our application's test. This is a Mocha driven
test suite.`}</p>
    <p><img parentName="p" {...{
        "src": "/assets/images/articles/gruntjs-workflow/file-system-pre-grunt.png",
        "alt": "Before Grunt.js File System",
        "title": "Before Grunt.js File System"
      }}></img></p>
    <p>{`First things first, lets get Grunt installed and integrated into the project so
we can start adding useful tasks.`}</p>
    <h3>{`Installation`}</h3>
    <p>{`Lets install the Grunt CLI globally so we can access the "grunt" command. To
install Grunt run the following command. I assume you already have
`}<a parentName="p" {...{
        "href": "http://nodejs.org/"
      }}>{`Node.js`}</a>{` installed. The job of the grunt command is to load
and run the version of Grunt you have installed locally to your project,
irrespective of its version. If you have some projects using Grunt 0.4 and
others using Grunt 0.3, the grunt-cli will select and run the proper grunt
installation.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` grunt-cli -g`}</code></pre></div>
    <p>{`You can check and make sure Grunt is installed correctly by asking the program
for it's version.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`grunt --version`}</code></pre></div>
    <h3>{`Project Integration`}</h3>
    <p>{`Time to integrate Grunt with our project. First lets create a package.json file
so we can quickly track and install our applications dependencies. What
dependencies? Well, we will need to add Grunt.js as one. Soon we will add our
third party Grunt tasks here too!`}</p>
    <p>{`Having a package.json file is not required to use Grunt, however it makes
managing our third party tasks a lot easier! You will see what I mean in a
little bit. For now, we can create a package.json at the root of our project
with the following contents.`}</p>
    <h4>{`package.json`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Example"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"version"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"0.0.1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"private"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"devDependencies"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"grunt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"latest"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`If you already have a package.json file and you want to add Grunt to your
project. You can do that by running the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` grunt-cli --save-dev`}</code></pre></div>
    <p>{`Terrific now it is time to create our Gruntfile! A Gruntfile is a JavaScript
file that Grunt leverages to understand your projects tasks and configuration.
When you run "grunt" from the command line, Grunt will recurse upwards till it
finds your Gruntfile. This functionality allows you to run Grunt from any sub
directory of your project.`}</p>
    <h4>{`Gruntfile.js`}</h4>
    <p>{`This is the basis of a Grunt file, it's a wrapper function that takes in "grunt"
as an argument. This allows us to register tasks and configuration with grunt
(and leverage Grunt's APIs) before Grunt actually runs any tasks. Think of this
as an entry point of sorts for Grunt.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`exports`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`grunt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// set up grunt`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Well done, you now have Grunt installed. Go ahead, and run "grunt" from your
command line at the root of your project. You will see a warning that it can't
find a default task. Very well then, lets go about adding some tasks!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ grunt
Warning: Task `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"default"`}</span>{` not found. Use --force to continue.

Aborted due to warnings.`}</code></pre></div>
    <h3>{`Automate The Grunt Work`}</h3>
    <p>{`Wouldn't it be great if we could run our JavaScript code through JSHint and get
all sorts of great feedback on making our code more consistent and less buggy?
Fortunately, Grunt has a few common tasks built right in, code linting with
JSHint is one of them. Lets modify our Gruntfile to get this working!`}</p>
    <h4>{`Gruntfile.js`}</h4>
    <p>{`First install the JSHint task by running this command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` grunt-contrib-jshint --save-dev`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`/*global module:false*/`}</span>{`
module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`exports`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`grunt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`initConfig`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    jshint`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      src`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Gruntfile.js"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"src/app/**/*.js"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"src/config.js"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"tests/app/**/*.js"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      options`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        curly`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        eqeqeq`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        immed`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        latedef`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        newcap`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        noarg`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        sub`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        undef`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        boss`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        eqnull`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        browser`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        globals`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          require`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          define`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          requirejs`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          describe`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          expect`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          it`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Load JSHint task`}</span>{`
  grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`loadNpmTasks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"grunt-contrib-jshint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Default task.`}</span>{`
  grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`registerTask`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"default"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"jshint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Here we install and configure the "jshint" task telling it which files to lint
using `}<a parentName="p" {...{
        "href": "https://github.com/isaacs/minimatch"
      }}>{`minimatch`}</a>{` style globbing. We also
configure `}<a parentName="p" {...{
        "href": "http://www.jshint.com/"
      }}>{`JSHint`}</a>{` to our liking, adding globals that
are ok and making sure it only complains when we violate our decided coding
standards. We then create an alias called "default", and we tell it to run the
"jshint" task.`}</p>
    <p>{`Go ahead and run grunt again…`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ grunt

Running `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"jshint:src"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`jshint`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` task
Lint free.

Done, without errors.`}</code></pre></div>
    <p>{`We can also run the jshint task directly!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ grunt jshint

Running `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"jshint:src"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`jshint`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` task
Lint free.

Done, without errors.`}</code></pre></div>
    <p>{`Wouldn't it be great if we could lint our code automatically every time one of
our linted files changes? Thats easy enough thanks to the grunt "watch" task.
Let's install and add some "watch" configuration to your grunt.initConfig call.`}</p>
    <p>{`First we install it:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` grunt-contrib-watch --save-dev`}</code></pre></div>
    <p>{`Then we configure and load it:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`initConfig`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// …`}</span>{`
  watch`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    files`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<%= jshint.src %>"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    tasks`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"jshint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// …`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`loadNpmTasks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"grunt-contrib-watch"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`This tells the grunt "watch" task (a built in task), to run the "lint" task
every time one of the configuration specified lint files changes! The sharp
reader will notice the use of
`}<a parentName="p" {...{
        "href": "http://lodash.com/docs#template"
      }}>{`lodash templates`}</a>{` to reference the "lint"
configuration from "watch".`}</p>
    <p>{`While code linting is a great tool to try an manage code quality it hardly
provides the confidence we need to ship our application to production. Lets set
up Grunt to run our `}<a parentName="p" {...{
        "href": "http://visionmedia.github.com/mocha/"
      }}>{`Mocha`}</a>{` specs in
PhantomJS, that should give us a bit more confidence before shipping.`}</p>
    <p>{`Before we set out to write our own Grunt task for Mocha, lets check NPM and make
sure nobody else has implemented one already.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` search gruntplugin mocha

NAME                  DESCRIPTION
grunt-mocha           Grunt task `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` running Mocha specs
grunt-mocha-test      A grunt task `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` running server side mocha tests
grunt-simple-mocha    A simple wrapper `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` running tests with Mocha.
istanbul-grunt-mocha  Grunt task `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` running Mocha specs, writing istanbul code`}</code></pre></div>
    <p>{`What do you know!? Looks like someone has already created a Grunt task to run
Mocha specs! Lets go ahead and leverage it!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` grunt-mocha -D`}</code></pre></div>
    <p>{`This command installs `}<a parentName="p" {...{
        "href": "https://github.com/kmiyashiro/grunt-mocha"
      }}>{`grunt-mocha`}</a>{`
and saves it as a development dependency in our package.json. This is useful
when someone else clones our repository; instead of tracing down our
dependencies manually, they can simply run "npm install" and NPM will download
the correct version of `}<a parentName="p" {...{
        "href": "https://github.com/kmiyashiro/grunt-mocha"
      }}>{`grunt-mocha`}</a>{`
for them!`}</p>
    <p>{`Now that grunt-mocha is installed we need to load it into our project using the
grunt.loadNpmTasks method, which allows us to load in grunt tasks from NPM
installed dependencies.`}</p>
    <h4>{`grunt.js`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// …`}</span>{`
modules`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`exports`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`grunt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

  grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`initConfig`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`…`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`loadNpmTasks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'grunt-mocha'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Now that Grunt is loading in grunt-mocha, lets configure it to run our tests.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`initConfig`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// …`}</span>{`
  mocha`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    all`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"tests/index.html"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// …`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The tests/index.html file is a basic Mocha test runner, much like the one you
see if you run "mocha init" from the command line. Now we can execute our mocha
tests in PhantomJS from Grunt.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ grunt mocha

Running `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mocha:all"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`mocha`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` task
Testing index.html.OK
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` assertions passed `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.04s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Pretty cool, eh? With, 3 lines of configuration and a method call we are running
our tests in PhantomJS. Lets add the mocha task to our watch command so each
time a linted file changes we execute our tests as well as lint our code.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`initConfig`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// …`}</span>{`
  watch`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    files`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`%=`}</span>{` jshint`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`src `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    tasks`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'jshint'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mocha'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// …`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Now when we execute "grunt watch" and change a file we get immediate feedback on
our tests and code quality.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ grunt `}<span parentName="code" {...{
            "className": "token function"
          }}>{`watch`}</span>{`
Running `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"watch"`}</span>{` task
Waiting`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.OK
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` File `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Gruntfile.js"`}</span>{` renamed.

Running `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"jshint:src"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`jshint`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` task
Lint free.

Running `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mocha:all"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`mocha`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` task
Testing index.html.OK
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` assertions passed `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.04s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Pretty sweet, huh!?`}</p>
    <h2>{`Custom Tasks`}</h2>
    <p>{`Obviously Grunt.js isn't limited to code quality tasks. To demonstrate how
generic a Grunt.js task can be lets write a custom task to compliment us every
time we run grunt.`}</p>
    <p>{`Lets start out by registering a task called "compliment".`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`exports`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`grunt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// …`}</span>{`
  grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`registerTask`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"compliment"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`log`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`writeln`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"You are so awesome!"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// …`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`With a call to register task, and simple call back function we have a custom
Grunt task. We can run it from the command line directly just like we ran lint
or mocha.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ grunt compliment

Running `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"compliment"`}</span>{` task
You are so awesome`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`

Done, without errors.`}</code></pre></div>
    <p>{`Well, thank you Grunt. I think you are awesome too.`}</p>
    <p>{`Wouldn't it be great if users could customize their compliments using their
grunt configuration and have a random compliment each time? Simple enough…`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`initConfig`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//...`}</span>{`
  compliment`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"You are so awesome!"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"You remind me of Brad Pitt, only you have a better body."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"You are a funny, funny kid."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`registerTask`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"compliment"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Treat yo' self!"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` defaults `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"No one cares to customize me."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Notice we use the grunt object to retrieve configuration.`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` compliments `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"compliment"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` defaults`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` index `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Math`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`floor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Math`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`random`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` compliments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`log`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`writeln`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`compliments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Observant readers will note that I added a description argument, this is used
when consumers run grunt -h…`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ grunt -h

…
  compliment  Treat yo' self`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
…`}</code></pre></div>
    <p>{`We pull the compliment array out of the configuration using the grunt.config
function, we then select a random compliment and echo it to the user. Lets add
this to our default just before lint and test.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// …`}</span>{`
grunt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`registerTask`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"default"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"compliment"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"jshint"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mocha"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Now when we run "grunt" we get a fresh compliment. My day is already starting to
look up.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ grunt

Running `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"compliment"`}</span>{` task
You are a funny, funny kid.

Running `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"jshint:src"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`jshint`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` task
Lint free.

Running `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mocha:all"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`mocha`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` task
Testing index.html.OK
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` assertions passed `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.04s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

Done, without errors.`}</code></pre></div>
    <p>{`If you are interested in writing Grunt tasks others can use, see
`}<a parentName="p" {...{
        "href": "https://github.com/iammerrick/grunt-compliment"
      }}>{`grunt-compliment`}</a>{` where I
refactored this "compliment" task and published it NPM.`}</p>
    <h2>{`Wrapping it up...`}</h2>
    <p>{`Grunt is a powerful tool and I've only scratched the surface in this article.
Try to leverage it in your next project and if it doesn't completely refactor
your workflow `}<a parentName="p" {...{
        "href": "mailto:merrick.christensen@gmail.com"
      }}>{`email me`}</a>{` and tell me why.
Leave the grunt work to Grunt.js so your mind and time can be liberated for the
hard problems.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      